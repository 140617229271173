<template>
  <div class="px-10 pt-12">
    <h2 class="mb-10">Set a password</h2>
    <ValidationObserver ref="emailForm" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" method="post">
        <ValidationProvider mode="eager" rules="required|min:8|max:255" vid="password" name="Password" v-slot="{ errors }">
          <div>
            <input
              v-model="form.password"
              type="password"
              class="form-input"
              :class="errors.length ? 'border border-red-600' : ''"
              placeholder="Your password"
            />
            <p class="w-full text-red-600 flex justify-center mt-1 text-sm"> {{ errors[0] }} </p>
          </div>
        </ValidationProvider>

        <ValidationProvider mode="eager" rules="required|confirmed:password|min:8|max:255" vid="password_confirmation" name="Password confirmation" v-slot="{ errors }">
          <div class="mt-4">
            <input
              v-model="form.confirm_password"
              type="password"
              class="form-input"
              :class="errors.length ? 'border border-red-600' : ''"
              placeholder="Confirm your password"
            />
            <p class="w-full text-red-600 flex justify-center mt-1 text-sm"> {{ errors[0] }} </p>
          </div>
        </ValidationProvider>

        <div class="absolute px-10 w-full left-0 right-0 bottom-0">
          <button
            class="btn-primary text-white bg-lightPurple w-full capitalize text-base block text-center"
            type="submit"
          >
            Continue
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import { SET_FORM } from '@/store/types/mutations';
import { SIGN_UP } from '@/store/types/actions';

export default {
  name: "signUpPassword",

  data: () => ({
    form: {
      password: '',
      confirm_password: ''
    }
  }),

  methods: {
    ...mapActions({
      signUp: `auth/${SIGN_UP}`
    }),

    ...mapMutations({
      setForm: `auth/${SET_FORM}`
    }),

    submit() {
      this.setForm(this.form);

      try {
        this.signUp();
        // this.$router.push({ name:'home' });
        // this.$router.push({ name:'signUpProPlan' });
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>

</style>
